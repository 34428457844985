import core from '@/admin/core';
import axios from 'axios';
import myAttributes from '@/composition/myAttributes';

type StatType = 'userInfos' | 'userCounts' | 'salesInfos' | 'subscriptionCounts';
interface StatUrl {
  signedUrl: string;
}

export interface User {
  birthdate: string;
  createDate: string;
  deleteDate: string;
  gender: string;
  isDeleted: boolean;
  isFailedLatestSubscriptionPayment: boolean;
  isUncompletedSignup: boolean;
  occupation: string;
  prefecture: string;
  seasonIds: number[];
  subscriptionPlanIds: string[];
  username: string;
}
export interface UserInfos {
  date: string;
  time: string;
  users: User[];
}

export interface Count {
  total: number;
  active: number;
  deleted: number;
  new: number;
  leave: number;
}
export interface UserCounts {
  [date: string]: {
    time: string;
    counts: Count;
  };
}

interface SalesInfoItem {
  id: string;
  salesTime: string;
  salesTimeRange: string;
  paymentType: 'credit' | 'convenienceStore' | 'docomo' | 'au' | 'softbank' | 'paypay';
  price: number;
  totalPrice: number;
  count: number;
  type: string;
}
export interface SalesInfo {
  time: string;
  sales: SalesInfoItem[];
}
export interface SalesInfos {
  [date: string]: SalesInfo;
}
export interface SalesInfosWithTimeRange {
  '00:00 - 01:00'?: SalesInfoItem[];
  '01:00 - 02:00'?: SalesInfoItem[];
  '02:00 - 03:00'?: SalesInfoItem[];
  '03:00 - 04:00'?: SalesInfoItem[];
  '04:00 - 05:00'?: SalesInfoItem[];
  '05:00 - 06:00'?: SalesInfoItem[];
  '06:00 - 07:00'?: SalesInfoItem[];
  '07:00 - 08:00'?: SalesInfoItem[];
  '08:00 - 09:00'?: SalesInfoItem[];
  '09:00 - 10:00'?: SalesInfoItem[];
  '10:00 - 11:00'?: SalesInfoItem[];
  '11:00 - 12:00'?: SalesInfoItem[];
  '12:00 - 13:00'?: SalesInfoItem[];
  '13:00 - 14:00'?: SalesInfoItem[];
  '14:00 - 15:00'?: SalesInfoItem[];
  '15:00 - 16:00'?: SalesInfoItem[];
  '16:00 - 17:00'?: SalesInfoItem[];
  '17:00 - 18:00'?: SalesInfoItem[];
  '18:00 - 19:00'?: SalesInfoItem[];
  '19:00 - 20:00'?: SalesInfoItem[];
  '20:00 - 21:00'?: SalesInfoItem[];
  '21:00 - 22:00'?: SalesInfoItem[];
  '22:00 - 23:00'?: SalesInfoItem[];
  '23:00 - 00:00'?: SalesInfoItem[];
}

export interface SubscriptionCountsPlan {
  subscriptionPlanId: string;
  count: number;
  trialCount: number;
  totalCount: number;
}
export interface SubscriptionCounts {
  [date: string]: {
    time: string;
    plans: SubscriptionCountsPlan[];
  };
}

export type SalesInfosTime = keyof SalesInfosWithTimeRange;

export const salesInfosTimes: SalesInfosTime[] = [
  '00:00 - 01:00',
  '01:00 - 02:00',
  '02:00 - 03:00',
  '03:00 - 04:00',
  '04:00 - 05:00',
  '05:00 - 06:00',
  '06:00 - 07:00',
  '07:00 - 08:00',
  '08:00 - 09:00',
  '09:00 - 10:00',
  '10:00 - 11:00',
  '11:00 - 12:00',
  '12:00 - 13:00',
  '13:00 - 14:00',
  '14:00 - 15:00',
  '15:00 - 16:00',
  '16:00 - 17:00',
  '17:00 - 18:00',
  '18:00 - 19:00',
  '19:00 - 20:00',
  '20:00 - 21:00',
  '21:00 - 22:00',
  '22:00 - 23:00',
  '23:00 - 00:00',
];

const getStatUrl = async (type: StatType) => {
  const result = await core.httpClient.get(`/admin/public/stat/downloadSignedUrl?dataType=${type}`);
  return result.data as StatUrl;
};

export const getStatUserInfos = async () => {
  if (myAttributes.myRequestPermissions?.stat) {
    try {
      const jsonUrl = (await getStatUrl('userInfos')).signedUrl;
      const userInfos = await axios.get<UserInfos>(jsonUrl);
      return userInfos.data;
    } catch {
      return null;
    }
  } else return null;
};

export const getStatUserCounts = async () => {
  if (myAttributes.myRequestPermissions?.stat) {
    try {
      const jsonUrl = (await getStatUrl('userCounts')).signedUrl;
      const userInfos = await axios.get<UserCounts>(jsonUrl);
      return userInfos.data;
    } catch {
      return null;
    }
  } else return null;
};
export const getStatSalesInfos = async () => {
  if (myAttributes.myRequestPermissions?.stat) {
    try {
      const jsonUrl = (await getStatUrl('salesInfos')).signedUrl;
      const userInfos = await axios.get<SalesInfos>(jsonUrl);
      return userInfos.data;
    } catch {
      return null;
    }
  } else return null;
};
export const getStatSubscriptionCounts = async () => {
  if (myAttributes.myRequestPermissions?.stat) {
    try {
      const jsonUrl = (await getStatUrl('subscriptionCounts')).signedUrl;
      const userInfos = await axios.get<SubscriptionCounts>(jsonUrl);
      return userInfos.data;
    } catch {
      return null;
    }
  } else return null;
};
